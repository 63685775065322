import { ContentGroupsType, ReverseOrder } from '../API';

interface SelectOption {
  value: string;
  label: string;
}

export function getReverseOrderOptions(): SelectOption[] {
  const COPY = {
    [ReverseOrder.NONE]: 'Season & Episodes ASC',
    [ReverseOrder.SEASONS_EPISODES]: 'Season & Episodes DESC',
    [ReverseOrder.SEASONS]: 'Seasons DESC'
  };

  return Object.keys(ReverseOrder).map((key) => ({
    value: key,
    label: COPY[key as ReverseOrder] || titleize(key)
  }));
}

export function getContentGroupOptions(): SelectOption[] {
  return Object.keys(ContentGroupsType).map((key) => ({
    value: key,
    label: titleize(key)
  }));
}

function titleize(str: string): string {
  const smallWords = /^(a|an|and|as|at|but|by|for|if|in|nor|of|on|or|so|the|to|up|yet)$/i;

  return str
    .toLowerCase()
    .replace(/_/g, ' ') // Replace underscores with spaces
    .split(' ')
    .map((word, index) => {
      if (index === 0 || !smallWords.test(word)) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word;
    })
    .join(' ');
}
