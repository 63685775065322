import React from 'react';
import { Box, IconButton, InputLabel, MenuItem, Typography } from '@mui/material';
import { useLocales, useTheme } from '../../../../hooks';
import { makeStyles } from 'tss-react/mui';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { markAsRequired } from '../../../../utils/formHelpers';
import FormControl from '../../FormControl';
import Select from '../../Select';
import FoldableSection from '../../FoldableSection';
import { getReverseOrderOptions, getContentGroupOptions } from '../../../../utils/contentService';
import Button from '../../Button';
import { ContentGroupRegionsBody, ContentGroupsRegionsBody, ContentGroupsType, ReverseOrder } from '../../../../API';
import { useData } from '../../../../data-layer';
import { usePermissionsGuard } from '../../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../../state/theme';
import { useRecoilValue } from 'recoil';
import CountryPickerLite from '../../CountryPickerLite';
import { Sortable } from '../../../shared/Sortable';
import { Add, Delete } from '@mui/icons-material';

interface ReverseChronologicalProps {
  contentId?: string;
}

export interface ContentEntitlementFiltering {
  contentGroup: ContentGroupsType[];
  countryCodes: string[];
}

export interface ReverseChronological {
  reverseChronologicalOrder: string;
  contentEntitlementFiltering: ContentEntitlementFiltering[];
}

export const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
    margin: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  formRow: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center',
    flexWrap: 'wrap',
    verticalAlign: 'middle'
  },
  selector: {
    minWidth: 360
  },
  saveAndPublishBtn: {
    marginTop: theme.spacing(4)
  },
  countryPicker: {
    minWidth: 450,
    flexGrow: 1
  },
  dynamicFormRow: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    flexWrap: 'wrap',
    verticalAlign: 'middle'
  },
  dynamicFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    padding: theme.spacing(2)
  },
  dynamicFormAddRow: {
    alignSelf: 'flex-start'
  }
}));

export const testIds = {
  formBody: 'reverse-chron.form-body',
  reverseChronSelector: 'reverse-chron.selector',
  reverseChronSelectorItem: 'reverse-chron.selector-item',
  contentGroupSelector: 'content-group.selector',
  contentGroupSelectorItem: 'content-group.selector-item',
  contentEntitlementFilteringAddButton: 'content-entitlement-filtering.add-button',
  contentEntitlementFilteringRemoveButton: 'content-entitlement-filtering.remove-button'
};

export function ReverseChronological({ contentId }: ReverseChronologicalProps): JSX.Element {
  const { classes } = useStyles();
  const { formControlColor } = useTheme();
  const {
    media: {
      state: { withRecordById, withSearchParams },
      hook: { closeForm, patchContent }
    }
  } = useData();
  const { t } = useLocales();
  const { canPublish: canPublishReverseChron } = usePermissionsGuard({
    homepageOption: HomepageOptions.METADATA
  });
  const content = useRecoilValue(withRecordById(contentId));
  const lastSearch = useRecoilValue(withSearchParams);

  const methods = useForm<ReverseChronological>({
    defaultValues: {
      reverseChronologicalOrder: content?.reverseOrder || ReverseOrder.NONE
    }
  });
  const { control } = methods;

  const {
    append: appendContentEntitlementFiltering,
    remove: removeContentEntitlementFiltering,
    fields: fieldsContentEntitlementFiltering,
    replace: replaceContentEntitlementFiltering
  } = useFieldArray({ control, name: 'contentEntitlementFiltering' });

  const [isSaving, setIsSaving] = React.useState(false);

  const options = getReverseOrderOptions();

  const handleSubmit = async (data: ReverseChronological) => {
    if (contentId) {
      const reverseOrder = data.reverseChronologicalOrder as ReverseOrder;
      let contentGroups: ContentGroupRegionsBody | undefined = undefined;

      if (data.contentEntitlementFiltering.length > 0) {
        const regions: ContentGroupsRegionsBody[] = [];
        data.contentEntitlementFiltering.forEach(({ contentGroup, countryCodes }) => {
          regions.push({
            contentGroup,
            countryCodes
          });
        });
        contentGroups = { regions };
      }

      setIsSaving(true);
      const content = await patchContent(contentId, {
        reverseOrder,
        contentGroups,
        lastSearch
      });

      if (content) {
        closeForm();
        return;
      }
      setIsSaving(false);
    }
  };

  const handleSaveAndPublish = async () => {
    methods.handleSubmit(handleSubmit)();
  };

  const onAddContentEntitlementFiltering = () => {
    appendContentEntitlementFiltering({
      contentGroup: [],
      countryCodes: []
    });
  };

  return (
    <Box className={classes.root} data-testid={testIds.formBody}>
      <FormProvider {...methods} handleSubmit={methods.handleSubmit}>
        <Box className={classes.formContainer}>
          <FoldableSection
            summary={<Typography variant="h6">{t('content.drawer.reverse_chronological.foldable_title')}</Typography>}
            expanded
            disableGutters
          >
            <Box className={classes.form}>
              <div className={classes.formRow}>
                <Controller
                  control={control}
                  rules={{ required: t('general.field_is_required') }}
                  name="reverseChronologicalOrder"
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <FormControl>
                      <InputLabel id="typeSelector">
                        {markAsRequired(t('content.drawer.reverse_chronological.order'), true)}
                      </InputLabel>
                      <Select
                        label={markAsRequired(t('content.drawer.reverse_chronological.order'), true)}
                        className={classes.selector}
                        required
                        labelId="typeSelector"
                        value={value}
                        onChange={({ target: { value } }) => onChange(value as string)}
                        data-testid={testIds.reverseChronSelector}
                        error={!!error}
                        disabled={isSaving}
                      >
                        {options.map(({ value, label }) => (
                          <MenuItem
                            key={value}
                            value={value}
                            data-testid={testIds.reverseChronSelectorItem}
                            data-treatment-id={value}
                          >
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>
            </Box>
          </FoldableSection>
          <FoldableSection
            summary={
              <Typography variant="h6">{t('content.drawer.content_entitlement_filtering.foldable_title')}</Typography>
            }
            disableGutters
            expanded={fieldsContentEntitlementFiltering.length > 0}
          >
            <div className={classes.dynamicFormContainer}>
              <Sortable
                list={fieldsContentEntitlementFiltering}
                setList={replaceContentEntitlementFiltering}
                ghostClass="sortableGhost"
                dragClass="sortableDragDefault"
              >
                {fieldsContentEntitlementFiltering.map((contentEntitlementFiltering, i) => (
                  <Box key={contentEntitlementFiltering.id} className={classes.form}>
                    <div className={classes.dynamicFormRow}>
                      <Controller
                        control={control}
                        name={`contentEntitlementFiltering.${i}.contentGroup`}
                        rules={{ required: t('general.field_is_required') }}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <FormControl>
                            <InputLabel id="contentGroupSelector">
                              {markAsRequired(t('content.drawer.content_entitlement_filtering.content_group'), true)}
                            </InputLabel>
                            <Select
                              label={markAsRequired(
                                t('content.drawer.content_entitlement_filtering.content_group'),
                                true
                              )}
                              className={classes.selector}
                              required
                              labelId="contentGroupSelector"
                              value={value}
                              onChange={({ target: { value } }) => onChange(value as unknown as ContentGroupsType[])}
                              data-testid={testIds.contentGroupSelector}
                              error={!!error}
                              multiple
                              disabled={isSaving}
                            >
                              {getContentGroupOptions().map(({ value, label }) => (
                                <MenuItem
                                  key={value}
                                  value={value}
                                  data-testid={testIds.contentGroupSelectorItem}
                                  data-treatment-id={value}
                                >
                                  {label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                      <Controller
                        control={control}
                        name={`contentEntitlementFiltering.${i}.countryCodes`}
                        rules={{ required: t('general.field_is_required') }}
                        render={({ field: { value, onChange } }) => (
                          <FormControl>
                            <CountryPickerLite
                              label={markAsRequired(t('media.countries'), true)}
                              className={classes.countryPicker}
                              value={value || []}
                              onChange={onChange}
                              required
                              disabled={isSaving}
                            />
                          </FormControl>
                        )}
                      />
                      <IconButton
                        title={t(
                          'content.drawer.content_entitlement_filtering.actions.remove_content_entitlement_filtering'
                        )}
                        onClick={() => removeContentEntitlementFiltering(i)}
                        sx={{ mt: 4 }}
                        data-testid={testIds.contentEntitlementFilteringRemoveButton}
                        disabled={isSaving}
                      >
                        <Delete />
                      </IconButton>
                    </div>
                  </Box>
                ))}
              </Sortable>
              <Button
                startIcon={<Add />}
                color={formControlColor}
                variant="outlined"
                onClick={onAddContentEntitlementFiltering}
                data-testid={testIds.contentEntitlementFilteringAddButton}
                className={classes.dynamicFormAddRow}
                disabled={isSaving}
              >
                {t('content.drawer.content_entitlement_filtering.actions.add_content_entitlement_filtering')}
              </Button>
            </div>
          </FoldableSection>
        </Box>
        <Box className={classes.saveAndPublishBtn}>
          <Button onClick={handleSaveAndPublish} disabled={!canPublishReverseChron || isSaving}>
            {t('general.update')}
          </Button>
        </Box>
      </FormProvider>
    </Box>
  );
}
