import {
  AssetBody,
  ApiResponseOfAssetResponse,
  AssetsApi,
  Configuration,
  FocusMode,
  FocusOptionsQuery
} from '../../../API';
import { AxiosResponse } from 'axios';
export class AssetsAPI {
  api: AssetsApi;

  getPaginated!: typeof AssetsApi.prototype.getPaginated;
  getByFilePath!: typeof AssetsApi.prototype.getByFilePath;
  getById!: typeof AssetsApi.prototype.getById;
  update!: typeof AssetsApi.prototype.update;
  remove!: typeof AssetsApi.prototype.remove;

  constructor(config: Configuration) {
    const api = new AssetsApi(config);
    Object.assign(this, api);

    this.api = api;

    this.getPaginated = api.getPaginated;
    this.getByFilePath = api.getByFilePath;
    this.getById = api.getById;
    this.remove = api.remove;
    this.update = api.update;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  create(asset: AssetBody, file: File): Promise<AxiosResponse<ApiResponseOfAssetResponse, any>> {
    return this.api.create(
      asset.assetId,
      asset.assetName,
      asset.assetType,
      {
        mode: asset.focusOptions?.mode || FocusMode.AUTO,
        focusX: asset.focusOptions?.focusX,
        focusY: asset.focusOptions?.focusY
      } as unknown as FocusOptionsQuery,
      file
    );
  }
}
