/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum ContentGroupsType {
    MID = 'MID',
    PROMO = 'PROMO',
    CATCHUP = 'CATCHUP',
    VIX = 'VIX',
    PROMO_US = 'PROMO_US',
    FIRST_PARTY = 'FIRST_PARTY',
    PROMO_MX = 'PROMO_MX',
    PROMO_ROLAC = 'PROMO_ROLAC',
    PROMO_GLOBAL = 'PROMO_GLOBAL',
    REGIONAL_UPSELL = 'REGIONAL_UPSELL'
}



